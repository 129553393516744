@import 'material-icons/iconfont/material-icons.css';

@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  src: url("./assets/fonts/AkkuratLLWeb-Thin.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  font-style: italic;
  font-family: "AkkuratLLWeb-ThinItalic";
  src: url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  src: url("./assets/fonts/AkkuratLLWeb-Light.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-LightItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  src: url("./assets/fonts/AkkuratLLWeb-Regular.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-Italic.woff") format("woff"),url("./assets/fonts/AkkuratLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  src: url("./assets/fonts/AkkuratLLWeb-Bold.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff2") format("woff2");
}


@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  src: url("./assets/fonts/AkkuratLLWeb-Black.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff2") format("woff2");
}

* { 
  box-sizing: border-box; 
  -webkit-tap-highlight-color: transparent; 
}

body {
  margin: 0;
  font-family: 'Akkurat', 'Helvetica Neue', sans-serif;
  font-size: .9em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: font-size .1s;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}